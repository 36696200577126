import { NavLink } from 'react-router-dom';
import './VideoNavbar.css';
import Logo from '../../Assets/PengImg.png';

const VideoNavbar = () => {
    return (
        <nav className="video-navbar-container">
            <div className="video-navbar-logo">
                <NavLink to="/">
                    <img src={Logo} alt="Navbar Penguin Logo" />
                </NavLink>
            </div>
        </nav>
    );
};

export default VideoNavbar;
