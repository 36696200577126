import { Outlet } from 'react-router-dom';
import VideoNavbar from '../components/Navbar/VideoNavbar';

const VideoLayout = () => {
    return (
        <main style={{ maxHeight: '100vh' }}>
            <VideoNavbar />
            <Outlet />
        </main>
    );
};

export default VideoLayout;
